<template>
  <GoogleMap
    ref="mapRef"
    :api-key="config.public['gmapsApiKey']"
    :center="center"
    :zoom="10"
    :map-type-id="layer"
    :map-type-control="false"
    :street-view-control="false"
    :fullscreen-control="false"
    :zoom-control="isDesktop ? true : false"
    class="map"
  >
    <CustomMarker :options="markerOptions">
      <nuxt-img
        v-if="visualRessourcesStory?.content?.marker_asset && visualRessourcesStory?.content?.marker_asset.filename != null && visualRessourcesStory?.content?.marker_asset.filename != ''"
        :src="visualRessourcesStory.content.marker_asset.filename"
        loading="lazy"
        alt=""
        provider="storyblok"
        class="marker"
      />
      <SvgIcon v-else name="func_gmaps-marker" class="marker" />
    </CustomMarker>
    <Marker
      v-if="showGeolocationMarker"
      :options="markerGeolocationOptions"
    />
    <CustomControl position="RIGHT_BOTTOM">
      <button
        class="btn-geolocation"
        :title="useMicroCopy('facilitymap.cta.geolocate.label')"
        @click="geolocate"
      >
        <SvgIcon name="func_focus" />
      </button>
    </CustomControl>
    <template v-if="props.polygons && props.polygonsData?.length">
      <Polygon v-for="polygon in props.polygonsData" :key="polygon.srcLocalId" :options="polygon" />
    </template>
  </GoogleMap>
</template>

<script setup>
import {
  GoogleMap,
  CustomMarker,
  Marker,
  CustomControl,
  Polygon
} from "vue3-google-map";
import { vintage } from "@/utils/mapStyle";

const isDesktop = inject("isDesktop");
const config = useRuntimeConfig();
const countryPrefix = config.public["storyblokCountryPrefix"];
const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
  polygons: {
    type: Boolean,
    required: false,
    default: false,
  },
  polygonsData: {
    type: Object,
    required: false,
    default: () => {},
  },
});

const { blok } = toRefs(props); // eslint-disable-line
const center = {
  lat: parseFloat(blok.value.latitude),
  lng: parseFloat(blok.value.longitude),
};
const markerOptions = { position: center, anchorPoint: "CENTER" };
const mapRef = ref(null);
const layer = "watercolor";

const geolocation = {
  lat: 0,
  lng: 0,
};
const showGeolocationMarker = ref(false);
const markerGeolocationOptions = {
  position: geolocation,
  anchorPoint: "CENTER",
};

const visualRessourcesStory = ref(null);

const getPosition = (position) => {
  geolocation.lat = position.coords.latitude;
  geolocation.lng = position.coords.longitude;
  mapRef.value.map.panTo({
    lat: position.coords.latitude,
    lng: position.coords.longitude,
  });
  showGeolocationMarker.value = true;
};

const geolocate = () => {
  navigator.geolocation.getCurrentPosition(getPosition, errorPosition);
};

const errorPosition = reactive((error) => {
  console.log("onError", error);
  return error;
});

await useCustomAsyncStoryblok("", {
  version: config.public["storyblokVersion"],
  by_slugs: `${countryPrefix}/visual-ressources`,
}).then((result) => {
  visualRessourcesStory.value = result.value.stories[0];
});

const reframeFacilityAndPolygons = () => {
  const gmap = mapRef.value.map;
  const api = mapRef.value.api;
  const bounds = new api.LatLngBounds();

  props.polygonsData.forEach((polygon) => {
    polygon.paths.forEach((path) => {
      bounds.extend(path);
    });
  });
  bounds.extend(center);
  gmap.fitBounds(bounds);
};

onMounted(() => {
  setTimeout(() => {
    if (mapRef.value?.ready && props.polygons && props.polygonsData.length) {
      reframeFacilityAndPolygons();
    }
  }, 100);
});

watch(
  () => mapRef.value?.ready,
  (ready) => {
    if (!ready) return;

    setTimeout(() => {
      const styledMapType = new google.maps.StyledMapType(vintage, {
        name: "Vintage",
      });
      mapRef.value.map.mapTypes.set("layer", styledMapType);
      mapRef.value.map.setMapTypeId("layer");
    }, 1000);
  }
);
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 100%;

  :deep(.mapdiv) {
    div {
      position: static;
      z-index: initial;
    }
  }
}

.marker {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: contain;

  @include for-desktop-up {
    width: 5rem;
    height: 5rem;
  }

  &:deep(path:first-of-type) {
    fill: var(--brand);
  }
}

.btn-geolocation {
  margin-right: 0.625rem;
  background-color: var(--solid-07);
  border-radius: 0.125rem;
  display: inline-flex;
  width: 2.5rem;
  justify-content: center;
  padding: 0.5rem 0;
  cursor: pointer;
  border: 0;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}
</style>